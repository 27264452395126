import $ from "jquery";
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/autocomplete';
import { IPredictiveProviderConfig } from './Interfaces/IPredictiveProviderConfig';
import { Configuration } from './Configuration/Configuration';

export class PredictiveProvider {
    private Config: IPredictiveProviderConfig;
    private Dictionary: object;
    private SelectedWithEnter: boolean;

    constructor(config: IPredictiveProviderConfig) {
        this.Config = config;
        this.Dictionary = {};
        this.SelectedWithEnter = false;
    }

    // read the taxonomy from the page. This is configurable
    public getPageTermIds() {
        let pageTermIds = [];
        let taxonomy = (window as any).VitalSite;

        if (taxonomy !== undefined) {
            taxonomy = (window as any).VitalSite.taxonomy;
        }

        // get taxonomy term and facet ids from page source.
        // must assign these ids via Administration Group in Taxonomy Config to "Pages"
        $(taxonomy).each((i, tax) => {
            $(tax.Terms).each((j, term) => {
                pageTermIds.push(term.TermId);
            });
        });
        return pageTermIds.join();
    }

    public getQueryStringObject(data) {
        let pairs;
        let queryStringObject = {};
        let terms = [];

        // Cleanup the input IDs
        data = data.replace(/VsMasterPage%24MainContent%24AdvancedSearchFields%24searchForm%24/g, '').replace(/%24InternalTextBox/g, '').replace(/%24InternalDropDownList/g, '').replace(/%24hiddenRadiusField/g, '').replace(/%24RadiusList/g, '');

        // Parse query string into object for easier manipulation
        pairs = data.split('&');
        pairs.forEach((pair, i) => {
            pair = pair.split('=');
            if (pair[0].startsWith('TermDropDownListByFacetContainer')) {
                pair[0] = pair[0] + '-' + i;
            }
            queryStringObject[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        // Fix the query string object to match what we need on the search results page.
        for (const property in queryStringObject) {
            if (property === 'cities') {
                if (queryStringObject[property].toLowerCase() !== 'any city') {
                    queryStringObject['city'] = queryStringObject[property];
                }
                delete queryStringObject[property];
            }

            if (property === 'UserLat') {
                queryStringObject['lat'] = queryStringObject[property];
                delete queryStringObject[property];
            }

            if (property === 'UserLng') {
                queryStringObject['lng'] = queryStringObject[property];
                delete queryStringObject[property];
            }

            if (property === 'UserPosition') {
                queryStringObject['userEnteredPosition'] = queryStringObject[property];
                delete queryStringObject[property];
            }

            // If term, add to terms array;
            if (property.startsWith('TermDropDownListByFacetContainer')) {
                terms.push(queryStringObject[property]);
                delete queryStringObject[property];
            }

            if (property === 'gender') {
                switch (queryStringObject[property]) {
                    case '1':
                        queryStringObject[property] = 'Male';
                        break;
                    case '2':
                        queryStringObject[property] = 'Female';
                        break;
                    default:
                        delete queryStringObject[property];
                }
            }

            if (property === 'acceptingNewPatients') {
                queryStringObject['anp'] = true;
                delete queryStringObject[property];
            }
        }

        // Add additional query string parameters
        if (terms.length > 0) {
            queryStringObject['termId'] = terms.join(',');
        }

        let newInputValue = <string>$(this.Config.newInput).val();

        if (newInputValue.length > 0 && this.Config.singleInput.length === 0) {
            if (this.Dictionary[newInputValue] !== undefined) {
                queryStringObject['first'] = this.Dictionary[newInputValue].FirstName;
                queryStringObject['last'] = this.Dictionary[newInputValue].LastName;
            } else {
                queryStringObject[this.Config.searchByWithEnterKey] = newInputValue;
            }
        }

        if ($.isEmptyObject(queryStringObject)) {
            queryStringObject['ignore'] = 'true';
        }

        queryStringObject['navigationNode'] = (window as any).Geonetric.Page.NavigationNode;
        queryStringObject['sort'] = this.Config.sort; // Best Match sort by default

        return queryStringObject;
    }

    public doSearch() {
        let data = $<HTMLInputElement>(this.Config.advancedFormInputs).filter(function () {
            return this.value !== '' && this.value !== '00000000-0000-0000-0000-000000000000';
        }).serialize();
        let queryStringObject = this.getQueryStringObject(data);
        (window as any).location = `/app/doctors/results.aspx?${$.param(queryStringObject)}`;
    }

    public Setup() {

        if (this.Config.singleInput.length === 0) {
            // build a single provider name input to perform the autocomplete on
            $(this.Config.firstInput).before(this.Config.newInputHTML(this.Config.newInput, this.Config.inputName));
            $(this.Config.firstInput + ',' + this.Config.lastInput).hide().find('input').val('');
        } else {
            this.Config.newInput = this.Config.singleInput;
        }

        let searchTerms = this.Config.usePageTermId ? this.getPageTermIds() : '';

        // get results from api
        (window as any).$.getProviderNamesList({
            SearchTermIds: searchTerms,
            version: 2
        }, (data) => {
            // if testNames is passed in we'll use that instead of the api results
            this.Config.testNames.length ? data = this.Config.testNames : '';

            data.map((el) => {
                this.Dictionary[`${el.FirstName} ${el.LastName}`] = el;
            });
            const keys = $.map(<any>this.Dictionary, (obj) => {
                return `${obj.FirstName} ${obj.LastName}`;
            });

            $(this.Config.newInput).autocomplete({
                source: keys,
                delay: this.Config.delay,
                minLength: this.Config.minLength
            });
        });

        $(document).on('keydown', this.Config.newInput, (event) => {
            // the Enter key has been pressed
            if (event.code === 'Enter' || event.key === 'Enter') {
                event.preventDefault();
                let resultsList = 'ul.ui-autocomplete.ui-front';

                if (this.Config.enterKeyToSelect) {
                    // if the autocomplete box is showing and the user hits "enter", select the first result and search on that
                    if ($(resultsList).is(':visible')) {
                        $(resultsList).find('li:first').click();
                        this.SelectedWithEnter = true;
                    }
                }

                if (this.Config.enterKeyToSubmit && !this.SelectedWithEnter && !$(resultsList).is(':visible')) {
                    this.doSearch();
                    return;
                }
                this.SelectedWithEnter = false;
            }
        });

        $(this.Config.submitBtn).on('click', (e) => {
            e.preventDefault();
            this.doSearch();
        });
    }
}

export function Initialize(userConfig: IPredictiveProviderConfig) {
    let config = new Configuration();
    Object.assign(config, userConfig);
    return new PredictiveProvider(config);
}

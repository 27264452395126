import { IPredictiveProviderConfig } from '../Interfaces/IPredictiveProviderConfig';

export class Configuration implements IPredictiveProviderConfig {
    advancedFormInputs: string = '#ProviderAdvancedSearch :input';
    submitBtn: string = '#ProviderAdvancedSearch [id*="searchForm_SubmitBtn"] .Button';
    firstInput: string = '.PhysicianFirst';
    lastInput: string = '.PhysicianLast';
    singleInput: string = '';
    minLength: number = 2;
    delay: number = 300;
    sort: number = 7;
    usePageTermId: boolean = true;
    enterKeyToSelect: boolean = true;
    enterKeyToSubmit: boolean = true;
    searchByWithEnterKey: string = 'keyword'; // first/last/keyword
    newInput: string = '#ProviderNameTextBox';
    inputName: string = 'providerPredictiveSearch';
    newInputHTML(newInput: string, inputName: string) {
        return `<div class="ProviderName">
            <label for="${newInput.replace(/[#.]/, '')}">Provider Name</label>
            <input type="text" ${newInput.includes('#') ? 'id=' : 'class='}"${newInput.replace(/[#.]/, '')}" maxlength="100" name="${inputName}">
        </div>`;
    };
    testNames: Array<any> = [];
}
